import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import i18n from "i18next";


import { Header } from '../components/Header'
import { container } from '../State';


function DashboardPage({ history }) {
    
    const {t} = useTranslation('common');


    // Global State
    const con = container.useContainer();

    const handleViewAssesment = (item) => {
        history.push({
            pathname: '/result',
            state: item
        })
    }

    const removeAssessments = () => {

        if (window.confirm('Remove Assessments? Please refresh page to take effect')) {
            localStorage.removeItem('discover-your-values-previousAssessments')
            history.push('/')
        } else {
            console.log('Cancel');
        }
    }

    const handleLanguagePick = (e) => {
        i18n.changeLanguage(e.target.value);
        con.setCurrentLanguage(e.target.value);
    }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('discover-your-values-previousAssessments'))) {
            con.setPreviousAssessments(JSON.parse(localStorage.getItem('discover-your-values-previousAssessments')))
        } else {
            con.setPreviousAssessments([])
        }
    }, [])

    return (
        <div>
            <Header />

            <div className="container mx-auto">

            <select onChange={handleLanguagePick}>
                <option value="en">{con.currentLanguage === "en" ? "English": "English"}</option>
                <option value="es">{con.currentLanguage === "es" ? "Español": "Español"}</option>
            </select>
            
                <h1 className="font-ibm text-center text-3xl p-4">
                    {t("Your Previous Assessments")}
                </h1>
                <hr></hr>
                <div className="container p-10">
                    {
                        (!(con.previousAssessments) || con.previousAssessments.length === 0 ) ?
                        <h5 className="font-ibm text-center text-1xl">{t('No previous assessments')}...</h5>
                        :
                        <ul className="px-0">
                        {
                            con.previousAssessments && con.previousAssessments.map((item, index) => {
                                return(
                                    <li key={index} className="border list-none rounded-sm px-3 py-3 cursor-pointer hover:bg-blue-300 hover:text-white" >
                                        <a onClick={() => { handleViewAssesment(item)}}>{t('Assessment')} {index + 1}</a>
                                    </li>
                                )
                            })
                        }
                        <a href="#">
                            <button 
                                onClick={removeAssessments}
                                className="font-ibm rounded py-1 text-xs">
                                {t("Clear All Assessments")}
                            </button>
                        </a>
                        </ul>
                    }
                </div>

                <div className="container p-10 text-center">
                    <button 
                        className="font-ibm text-white backgound-blue my-5 focus:outline-none h-12 px-6 text-lg rounded transition-colors duration-150 rounded-lg disabled:opacity-50 disabled:cursor-default"
                        onClick={()=> history.push('/assessment')}
                    >{t('Take Assessment')}</button>
                </div>
            </div>
        </div>
    )
}

export default DashboardPage
