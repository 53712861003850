import React from 'react'

import { useTranslation } from 'react-i18next'

const valueColorMap = {
  'self-direction': 'self-direction',
  stimulation: 'stimulation',
  hedonism: 'hedonism',
  achievement: 'achievement',
  power: 'power',
  universalism: 'universalism',
  benevolence: 'benevolence',
  tradition: 'tradition',
  conformity: 'conformity',
  security: 'security',
}


const ValuePriorities = ({ data }) => {

    const {t} = useTranslation(['questions']);

    
    const cols = () => {
      const rows = [...Array(Math.ceil(data.length / 19))]
      const productRows = rows.map((row, idx) => data.slice(idx * 19, idx * 19 + 19))
  
      return productRows.map((row, idx) => {
        return (
          <div className="w-full md:m-2 relative">
            {row.map((value, indx) => {
              return (
                <div
                  className={`widget m-2 relative w-full p-4 rounded-lg  shadow-md bg-white  border-l-8 border-${
                    valueColorMap[value.category.split(' ').join('-').toLocaleLowerCase()]
                  }`}
                >
                  <div className="flex items-center">
                    <div
                      className={`icon w-14 p-3.5 ${
                        ((idx === 0 && indx === 0) || (idx === 0 && indx === 1) || ((idx === 0 && indx === 2) || (idx === 0 && indx === 3) || (idx === 0 && indx === 4))) 
                        &&
                        valueColorMap[value?.category.split(' ').join('-').toLocaleLowerCase()]
                      } text-${
                        ((idx === 0 && indx === 0) || (idx === 0 && indx === 1) || ((idx === 0 && indx === 2) || (idx === 0 && indx === 3) || (idx === 0 && indx === 4)))
                        ? 'white' : 'black'
                      } rounded-full mr-3 text-center`}
                    >

                      {
                        idx === 1 ? ( indx + 1 + 19) : ( idx === 2 ? indx + 1 + (19+19) : indx + 1 ) // not very proud of this one D;
                      }
                    </div>
                    <div className="flex flex-col justify-center">
                      <div className="text-lg">{t(value.noun)}</div>
                      <div className="text-sm text-gray-400">{t(value.category)}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })
    }
  
    return <div className="lg:flex block relative">{cols()}</div>
  }


export { ValuePriorities }
