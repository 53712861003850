import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { container } from "./State";
import 'tailwindcss/tailwind.css'
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

// Translation Libraries 
import commonTranslationEN from './/locales/en/common.json'
import questionTranslationEN from './locales/en/questions.json'

import commonTranslationES from './locales/es/common.json'
import questionTranslationES from './locales/es/questions.json'

//translations
const resources = {
  en: {
    common: commonTranslationEN,
    questions: questionTranslationEN,
  },
  es: {
    common: commonTranslationES,
    questions: questionTranslationES,
  }
} 

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',     
    resources,

    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

ReactDOM.render(
  <container.Provider>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </container.Provider>,
  document.getElementById('root')
);
