// import MOCK_ANSWERS from './mock-answer.json'
const MOCK_ANSWERS = require("./mock-answer.json");
const MOCK_ANSWERS_2 = require("./mock-answers2.json");

function getAverageCategory(data, categoryName, numberOfQuestions) {
	let total = 0;

	data.forEach((item) => {
		if (item.category === categoryName) {
			total += parseInt(item.selectedValue);
		}
	});

	return (total / numberOfQuestions).toFixed(2);
}

function injectRanking(data) {
	var rank = 1;
	var id = 1;

	for (var i = 0; i < data.length; i++) {
		if (i > 0 && data[i].categoryAvg < data[i - 1].categoryAvg) {
			rank++;
		}
		data[i].rank = rank;
	}

	for (var i = 0; i < data.length; i++) {
		data[i].id = id;
		id++;
	}

	return data;
}

function getAverageCategories(data) {
	let unique_categories = {};
	let result = [];

	data.forEach((item) => {
		if (!(item.category in unique_categories)) {
			unique_categories[item.category] = item;
		}
	});

	return Object.values(unique_categories);
}

function purifyDataWithAvgCategory(data) {
	const purifiedData = [];
	let entry;

	const avgPower = getAverageCategory(data, "Power", 5);
	const avgAchievement = getAverageCategory(data, "Achievement", 6);
	const avgHedonism = getAverageCategory(data, "Hedonism", 3);
	const avgStimulation = getAverageCategory(data, "Stimulation", 3);
	const avgSelfDirection = getAverageCategory(data, "Self-direction", 6);
	const avgUniversalism = getAverageCategory(data, "Universalism", 9);
	const avgBenevolence = getAverageCategory(data, "Benevolence", 9);
	const avgTradition = getAverageCategory(data, "Tradition", 5);
	const avgConformity = getAverageCategory(data, "Conformity", 4);
	const avgSecurity = getAverageCategory(data, "Security", 7);

	data.forEach((item) => {
		switch (item.category) {
			case "Power":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgPower,
				};
				purifiedData.push(entry);
				break;

			case "Achievement":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgAchievement,
				};
				purifiedData.push(entry);

				break;

			case "Hedonism":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgHedonism,
				};
				purifiedData.push(entry);

				break;

			case "Stimulation":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgStimulation,
				};
				purifiedData.push(entry);

				break;

			case "Self-direction":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgSelfDirection,
				};
				purifiedData.push(entry);

				break;

			case "Universalism":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgUniversalism,
				};
				purifiedData.push(entry);

				break;

			case "Benevolence":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgBenevolence,
				};
				purifiedData.push(entry);

				break;

			case "Tradition":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgTradition,
				};
				purifiedData.push(entry);

				break;

			case "Conformity":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgConformity,
				};
				purifiedData.push(entry);

				break;

			case "Security":
				entry = {
					noun: item.noun,
					category: item.category,
					description: item.description,
					selectedValue: item.selectedValue,
					categoryAvg: avgSecurity,
				};
				purifiedData.push(entry);

				break;
		}
	});

	return injectRanking(
		purifiedData.sort(
			(a, b) => parseFloat(b.categoryAvg) - parseFloat(a.categoryAvg),
		),
	);
}

function sortByScore(data) {
	return data.sort(
		(a, b) => parseFloat(b.selectedValue) - parseFloat(a.selectedValue),
	);
}

export { purifyDataWithAvgCategory, getAverageCategories, sortByScore };

// console.log(getAverageCategories(purifyDataWithAvgCategory(MOCK_ANSWERS_2)))
