import { createContainer } from 'unstated-next';
import { useState } from 'react';

export const container = createContainer(() => {
    let [user, setUser] = useState(localStorage.getItem("discover-your-value"));
    let [assessmentResult, setAssessmentResult] = useState([])
    let [currentLanguage, setCurrentLanguage] = useState("en")
    // let [previousAssessments, setPreviousAssessments] = useState([])

    let [previousAssessments, setPreviousAssessments] = useState(JSON.parse(localStorage.getItem("discover-your-values-previousAssessments")) || [])

    return {
      user,
      setUser,

      assessmentResult,
      setAssessmentResult,

      previousAssessments,
      setPreviousAssessments,

      currentLanguage,
      setCurrentLanguage
    };
});